<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>Welcome To Busness Search</h2>
        </v-col>
        <v-col cols="12">
          <v-form ref="packageOneForm" v-model="packageOnevalid">
            <v-row>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-text-field
                  v-model="category"
                  label="Category"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-text-field
                  v-model="location"
                  label="Location"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-btn class="primary" @click="getList()">Search</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../firebaseConfig";

export default {
  name: "Home",
  data: () => ({
    packageOnevalid: "",
    category: "",
    location: "",
    token:
      "EAAKd8EjhoecBOZCyPElx69MAK0yFSCED3TM6uZAAYkyPbZBHAA1yvxI7tX8TJbZBoT4zADUyALcoaXtuNHk1bBAbOL7BiSTKwdXbcQRZB5ZACmvzJQ1iS8hzMkHk9eIgaSHvZCD6F0LbQT5GEXmZCif1NJ6ZA5CSK2qZAhEZB6PcQeZCtkf8md1ZAPXG7EvWfLeFZAkDbLxmKbd9DvKAbUUqiywQZDZD",
  }),
  methods: {
    async getList() {
      const functions = getFunctions(firebaseApp, "europe-west2");
      const facebookScraper = httpsCallable(functions, "facebookScraper");
      facebookScraper({
        cat: this.category,
        locat: this.location,
        token: this.token,
      }).then((result) => {
        console.log("result", result);
      });
    },
  },
};
</script>
