// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCq0qhhm8ABGw1SPEpkel2BLwJOf-1aNds",
  authDomain: "data-searcher.firebaseapp.com",
  projectId: "data-searcher",
  storageBucket: "data-searcher.appspot.com",
  messagingSenderId: "62938736497",
  appId: "1:62938736497:web:04ba32a8da0db7322278d0",
  measurementId: "G-82XMVQLYPR"
};

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const firebaseApp = initializeApp(firebaseConfig)