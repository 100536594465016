<template>
  <v-app>
    <v-app-bar app color="secondary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="../src/assets/datasearchlogo.png"
          transition="scale-transition"
          width="180"
          @click="home()"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
        class="primary"
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <footer class="py-4 secondary">
      <v-row class="px-4" justify="center" align="center">
        <v-col cols="6">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="../src/assets/datasearchlogo.png"
            transition="scale-transition"
            width="180"
          />
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn @click="termsAndConditions()" depressed class="links"
            >Terms & Conditions</v-btn
          >
          <v-btn @click="termsAndConditions()" depressed class="links"
            >Privacy Policy</v-btn
          >
          <!-- <a href="#" class="links">Terms & Conditions</a> -->
        </v-col>
      </v-row>
    </footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  methods: {
    home() {
      this.$router.push("/");
    },
    termsAndConditions() {
      this.$router.push("/terms-and-conditions");
    },
    termsAndConditions() {
      this.$router.push("/privacy-policy");
    },
  },
};
</script>

<style>
.links {
  text-decoration: none;
  font-size: 14px;
}
</style>
